import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Category } from '@models';  
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoriaService {
  private apiUrl = environment.apiPortal + environment.siteName+ '/categorias';

  constructor(private httpClient: HttpClient) {}

  public getAll(): Observable<{ categorias: Category[] }> {
    return this.httpClient.get<{ categorias: Category[] }>(this.apiUrl);
  }
}
