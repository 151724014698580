<div class="container">
  <div class="row">
    <div class="col-12 mt-5 mb-4">
      <h1 i18n="UserDetails | Datos de usuario" class="mat-typography__underlined">User Details</h1>
    </div>
  </div>
  <form [formGroup]="userForm" class="form">
    <div class="form-row">
      <div class="col-md-6">
        <mat-form-field appearance="fill">
          <mat-label i18n="nameUserPage | Nombre ">Name</mat-label>
          <input formControlName="nombre" matInput required placeholder="Nombre"
            [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" />
          <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
            <div i18n="nombreRequerido | El nombre es requerido" *ngIf="f.nombre.errors.required">Name is required</div>
          </div>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="fill">
          <mat-label i18n="apellidoUserPage | Apellido ">Last name</mat-label>
          <input formControlName="apellido" matInput required i18n-placeholder="LastName" placeholder="Last Name"
            [ngClass]="{ 'is-invalid': submitted && f.apellido.errors }" />
          <div *ngIf="submitted && f.apellido.errors" class="invalid-feedback">
            <div i18n="apellidoRequerido | El apellido es requerido" *ngIf="f.apellido.errors.required">Last name is
              requiredo</div>
          </div>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-2">
        <mat-form-field appearance="fill">
          <mat-label i18n="tipodedocumentoUserPage | Tipo de documento ">Document types</mat-label>
          <input required formControlName="tipoDocumento" matInput i18n-placeholder="tipodedocumento"
            placeholder="Document types">
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="fill">
          <mat-label i18n="numeroDeDocumentoUserPage | Número de documento">Document number</mat-label>
          <input required formControlName="numeroDocumento" matInput i18n-placeholder="tipodedocumento"
            placeholder="Document number">
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="fill">
          <mat-label i18n="paisEmisorUserPage | País emisor de documento">Country issuing document</mat-label>
          <mat-select formControlName="paisDocumento" i18n-placeholder="paisEmisor"
            placeholder="Country issuing document" [compareWith]="idComparator">
            <mat-option *ngFor="let p of paises$ | async" [value]="p">{{p.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-4">
        <mat-form-field appearance="fill">
          <mat-label i18n="telefonoUserPage | Teléfono  ">Phone</mat-label>
          <input required formControlName="telefono" matInput i18n-placeholder="telefono" placeholder="Phone">
        </mat-form-field>
      </div>
      <div class="col-md-8">
        <mat-form-field appearance="fill">
          <mat-label i18n="domicilioUserPage | Domicilio ">Address</mat-label>
          <input formControlName="domicilio" matInput required i18n-placeholder="domicilio" placeholder="Address">
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-2">
        <mat-form-field appearance="fill">
          <mat-label i18n="codigoPostalUserPage | Código Postal">Zip Code</mat-label>
          <input required formControlName="codigoPostal" matInput i18n-placeholder="codigoPostal"
            placeholder="Zip Code">
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="fill">
          <mat-label i18n="ciudadUserPage | Ciudad">City</mat-label>
          <input required formControlName="ciudad" matInput i18n-placeholder="ciudad" placeholder="City">
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="fill">
          <mat-label i18n="departamentoUserPage | State">State</mat-label>
          <input required formControlName="departamento" matInput i18n-placeholder="departamento" placeholder="State">
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="fill">
          <mat-label i18n=" paisDomicilioUserPage | País domicilio">Country</mat-label>
          <mat-select formControlName="paisDomicilio" i18n-placeholder="paisDomicilio"
            placeholder="Country of residence" [compareWith]="idComparator">
            <mat-option *ngFor="let p of paises$ | async" [value]="p">{{p.nombre}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-3 mb-5 mb-md-0 text-center" style="margin-bottom: 25px!important;">
        <button i18n="actualizarDatos | Actualizar datos" mat-flat-button class="mat-button-sm--block" color="primary"
          (click)="updateUserAndShowConfirmation()">
          Update Details
        </button>
      </div>
    </div>
  </form>
</div>