<div class="breadcrumb">
  <!-- appCats -->
  <div class="container">
    <a routerLink="/" i18n="Home">Home</a>
    <span> / </span> <span><a routerLink="/blog">Blog</a></span> <span> / </span> <span>{{ isEnglish ? 'Events' :
      'Eventos' }}</span>
  </div>
</div>

<!-- Eventos 
    nombre = Titulo español
    copete = Titulo ingles
    descripcionHTML = Descripcion español
    descripcion = Descripcion ingles
    fuente = Ubicacion
    fechaElementoInicio = Visible desde
    fechaElementoFin = Visible hasta 
    estadoElemento = Estado
    -->

<div class="container">
  <h1>{{ isEnglish ? 'Events' : 'Eventos' }}</h1>
  <div class="events">
    <article class="articles" *ngFor="let elemento of elementos$">
      <img [src]="elemento.detailImage" alt="{{ isEnglish ? elemento.copete : elemento.nombre }}">
      <div class="info-article">
        <h2>{{ isEnglish ? elemento.copete : elemento.nombre }}</h2>
        <p class='truncate' [innerHtml]="sanitizeHtml(isEnglish ? elemento.descripcion : elemento.descripcionHtml)"></p>
      </div>
      <div class="event-footer">
        <div class="event-location">
          <p>{{ elemento.fuente }}</p>
        </div>
        <div class="learn-more">
          <a [routerLink]="['/blog/event', elemento.id]">{{ isEnglish ? 'Learn more' : 'Saber más' }}</a>
        </div>
      </div>
    </article>
  </div>
</div>