<div class="breadcrumb">
  <div class="container">
    <a routerLink="/" i18n="Home">Home</a>
    <span> / </span>
    <a routerLink="/blog">Blog</a>
    <span> / </span>
    <a>{{ isEnglish ? 'Category' : 'Categoría' }}</a>
  </div>
</div>

<div class="container">
  <ng-container *ngIf="isLoading">
    <div class="loading">
    </div>
  </ng-container>

  <ng-container *ngIf="!isLoading">
    <ng-container *ngIf="elementos.length > 0; else noElements">
      <div *ngFor="let elemento of elementos" [ngClass]="{'show-flex': elemento.detailImage}" class="article">
        <img *ngIf="elemento.detailImage" [src]="elemento.detailImage"
          alt="{{ isEnglish ? elemento.copete : elemento.nombre }}">
        <div>
          <div class="info-article">
            <h1 class="title">{{ isEnglish ? elemento.copete : elemento.nombre }} <span class="nacionality"
                *ngIf="elemento.fuente">({{elemento.fuente}})</span></h1>
            <p [innerHtml]="sanitizeHtml(isEnglish ? elemento.descripcion : elemento.descripcionHtml)"></p>
          </div>
          <div class="footer" *ngIf="elemento.linkAdjunto || elemento.fechaElementoInicio">
            <span class="fecha" style="text-transform: capitalize;" *ngIf="elemento.fechaInfoInicio">{{elemento.fechaInfoInicio | date:'MMMM yyyy'}}</span>
            <a class="link" *ngIf="elemento.linkAdjunto" [href]="elemento.linkAdjunto" target="_blank">Link</a>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <!-- Template para cuando no hay elementos -->
  <ng-template #noElements>
    <div class="no-elements">
      <p>{{isEnglish ? 'Sorry, there are no items available.' : 'Lo sentimos, no hay elementos disponibles.'}}</p>
      <a routerLink="/blog" class="back-to-blog">{{isEnglish ? 'Back to Blog' : 'Volver al Blog' }}</a>
    </div>
  </ng-template>
</div>