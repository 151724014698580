<div class="breadcrumb">
  <!-- appCats -->
  <div class="container">
    <a routerLink="/" i18n="Home">Home</a>
    <span> / </span> <span><a>Blog</a></span>
  </div>
</div>
<div class="container">
<!-- Noticias -->
<div class="blog" *ngIf="categorias$">
  <div class="category">
    <h1 i18n="Titulo de noticias">News</h1>
    <div class="news-trends">
      <article class="articles" *ngFor="let subcategoria of categorias$[0]?.categorias">
        <img [src]="subcategoria.imagen" [alt]="isEnglish ? subcategoria.nombre_en : subcategoria.nombre_es">
        <div class="info-article">
          <h2>{{ isEnglish ? subcategoria.titulo_en : subcategoria.titulo_es }}</h2>
          <p>{{ isEnglish ? subcategoria.descripcion_en : subcategoria.descripcion_es }}</p>
        </div>
        <div class="learn-more">
          <a i18n="Boton de saber mas" [routerLink]="'/'+buildUrl(subcategoria.link)[0]" [queryParams]="buildUrl(subcategoria.link)[1]">Learn more</a>
        </div>
      </article>
    </div>
  </div>

  <!-- Eventos
    nombre = Titulo español
    copete = Titulo ingles
    descripcionHTML = Descripcion español
    descripcion = Descripcion ingles
    fuente = Ubicacion
    fechaElementoInicio = Visible desde
    fechaElementoFin = Visible hasta
    estadoElemento = Estado
    -->

  <div class="category">
    <h1 i18n="Titulo de eventos">Events<span class="more-events"><a [routerLink]="['/blog/events']">{{ isEnglish
          ? 'View more' : 'Ver más' }}</a></span></h1>
    <div class="events">
      <article class="articles" *ngFor="let elemento of elementos$">
        <img [src]="elemento.detailImage" alt="{{ isEnglish ? elemento.copete : elemento.nombre }}">
        <div class="info-article">
          <h2>{{ isEnglish ? elemento.copete : elemento.nombre }}</h2>
          <p class='truncate' [innerHtml]="sanitizeHtml(isEnglish ? elemento.descripcion : elemento.descripcionHtml)">
          </p>
        </div>
        <div class="event-footer">
          <div class="event-location">
            <p>{{ elemento.fuente }}</p>
          </div>
          <div class="learn-more">
            <a i18n="Boton de saber mas" routerLink="/blog/event/{{elemento.id}}">Learn more</a>
          </div>
        </div>

      </article>
    </div>
  </div>

  <!-- Testimonios -->
  <div class="category">
    <h1 i18n="Titulo de testimonios">Testimonials</h1>
    <div class="testimonials">
      <article class="articles" *ngFor="let subcategoria of categorias$[2]?.categorias">
        <img [src]="subcategoria.imagen" [alt]="isEnglish ? subcategoria.nombre_en : subcategoria.nombre_es">
        <div class="info-article">
          <h2>{{ isEnglish ? subcategoria.titulo_en : subcategoria.titulo_es }}</h2>
          <!-- <p>{{ isEnglish ? subcategoria.descripcion_en : subcategoria.descripcion_es }}</p> -->
        </div>
        <div class="learn-more">
          <a i18n="Boton de saber mas" [routerLink]="'/'+buildUrl(subcategoria.link)[0]" [queryParams]="buildUrl(subcategoria.link)[1]">Learn more</a>
        </div>
      </article>
    </div>
  </div>
</div>
</div>
