<!-- {{element?.titulo}}
{{element?.linkValor}}
{{element?.tipoBanner}}
{{element?.imagen}}
{{element?.tipoVisualizacion}}
{{element?.ancho}}
{{element?.orden}}
{{element?.lenguaje}}
{{element?.fechaDesde | date:'shortDate'}}
{{element?.fechaFin | date:'shortDate'}}
element?.html -->

<!--


<div *ngIf="element?.titulo === 'MIA Texto' ">
    <div class="col-sm-6 offset-sm-3 p-5 align-self-center">
        <p>You’ll find amazing offers on wellness and health experiences from
            stand up paddle board yoga to a complete health diagnosis, spa to
            cosmetic surgery – all with the highest level of biosafety and demanding protocols. And, at the
            same time, beautiful beaches, green spaces and fresh air are here for
            you to explore and enjoy, with sunny skies and warm temperatures
            waiting for you.</p>
    </div>
</div>


<div *ngIf="element?.titulo === 'MIA Footer' " class="full-width">
    <section id="AboutUs">
        <div class="container">
            <h2>ABOUT US</h2>
            <hr>
            <div id="divAbout">
                <div>
                    <ul>
                      <li>We are health professionals dedicated to wellness and health tourism in the USA.</li>
                      <li>We simplify the organization of all activities related to wellness and medical care.</li>
                      <li>We provide a total “human-centered technology platform” of wellness and medical care, private consultations with specialists in the best reference centers, innovative preventive medical check-up, second opinion and all required medication.</li>
                      <li>We are a “Personal Assistant Service” who coordinates different wellness and medical activities, always we advice and support our clients.</li>
                      <li>We also offer professional innovative services, personalized attention, regarding all wellness and health services, for travelers and tourists visiting Miami, its main objective is to receive, attend and resolve any need or concern that the tourist presents related to prevention or health care.</li>
                      <li>We specialize in advising and connecting customers with private organizations in wellness and health tourism, international health and the latest advance in preventive medicine.</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</div>
-->

<div [innerHTML]="element?.html | safeHtml:'html'" [ngClass]="{'d-block d-sm-none': element?.tipoVisualizacion === 'MOVIL', 'd-none d-sm-block': element?.tipoVisualizacion === 'DESKTOP'}"></div>
