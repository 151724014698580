<!-- {{element?.titulo}}
{{element?.linkValor}}
{{element?.tipoBanner}}
{{element?.imagen}}
{{element?.tipoVisualizacion}}
{{element?.ancho}}
{{element?.orden}}
{{element?.lenguaje}}
{{element?.fechaDesde | date:'shortDate'}}
{{element?.fechaFin | date:'shortDate'}} -->


<!-- {{element | json}} -->

<app-product-slider *ngIf="element?.html == 'destacados'" categoryParent="{{element.linkValor}}" style="display: contents"></app-product-slider>

<app-category-slider *ngIf="element?.html == 'categorias'"  class=" d-md-block col-12" categoryParent="{{element.linkValor}}" style="overflow: hidden;"></app-category-slider>
