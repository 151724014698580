import { environment } from "src/environments/environment";
import { BannerService } from "../../services/banner.service";
import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Category } from "src/app/models/category.model";
import { PlatformLocation } from "@angular/common";

@Component({
  selector: "app-banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.scss"],
})
export class BannerComponent implements OnInit {
  datos: any;
  _category: Category;
  @Input() set category(c: Category) {
    const url = this.platformLocation.href;
    const base = environment.urlApp;
    let lang = url.substr(base.length + url.indexOf(base), 2);

    if (lang !== "es") {
      lang = "en";
    }

    this._category = c;

    if (c !== undefined && c !== null) {
      this.bannerService
        .getBanner(environment.idStore, c.id, lang)
        .subscribe((respuesta) => {
          this.datos = respuesta;
        });
    }
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private bannerService: BannerService,
    private platformLocation: PlatformLocation
  ) {}

  ngOnInit(): void {}
}
