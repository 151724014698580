import { Injectable, Inject } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
//import {SearchDialogComponent} from '../components/search-dialog/search-dialog.component';
import { SEARCH_DIALOG_COMPONENT } from "../services/dialog.tokens";

@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(
    public dialog: MatDialog,
    @Inject(SEARCH_DIALOG_COMPONENT) private searchDialogComponent: any
  ) {}

  open() {
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    dialogConfig.height = "100%";
    dialogConfig.width = "100vw";
    dialogConfig.maxWidth = "100vw";
    this.dialog.open(this.searchDialogComponent, dialogConfig);
  }
  close() {
    this.dialog.closeAll();
  }
}
