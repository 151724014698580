import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LangService {

  public getLang() {
    const url = location.href;
    const urlArguments = url.substr(environment.urlApp.length);
    const lang = urlArguments.substr(0, 2);
    return lang;
  }

  public getLangV2(): Observable<string> {
    const url = location.href;
    const urlArguments = url.substr(environment.urlApp.length);
    const lang = urlArguments.substr(0, 2);

    return of(lang);
  }

}
