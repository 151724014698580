import {Title} from '@angular/platform-browser';
import {ConstantsUtils} from '@utils';

export abstract class AbstractPage {

  public titleService = ConstantsUtils.injector.get(Title);

  public abstract pageTitle(): string;
  public lang:string;

  constructor() {
    this.titleService.setTitle(this.pageTitle());
    this.lang = 'es'
  }

}
