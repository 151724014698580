// import { Multimedia } from "./multimedia.model";

export class ElementoFiltro {
	public id!: number;
  	public categorias!: any[];
	public categoria1!: string;
	public categoria2!: string;
	public categoria3!: string;
	public tipoInfo!: string;
	public tipoModulo!: string;
	public nombre!: string;
	public copete!: string;
	public descripcion!: string;
	public descripcionHtml!: string;
	public fuente!: string;
	public estadoModulo!: string;
	public fechaInfoInicio!: Date;
	public fechaInicioInfoDesde!: Date;
	public fechaInicioInfoHasta!: Date;
	public fechaInfoFin!: Date;
	public fechaFinInfoDesde!: Date;
	public fechaFinInfoHasta!: Date;
	public institucion!: string;
	public institucionLink!: string;
	public linkImagen!: string;
	public linkElemento!: string;
	public linkArchivo!: string;
	public linkVideo!: string;
	// public multimedia!: Multimedia[];
	public pais!: string;
	public precio!: number;
	public moneda!: string;
	public lenguaje!: string;
	public estado!: string;
	public fechaInicio!: Date;
	public fechaFin!: Date;
	public rolMinimo!: number;
	public modulo!: string;
	public orden!: number;
	public contacto!: string;
	public infoAdicional!: string;
	public visitas!: string;
	public votos!: string;
	public link!: string;
	public iframe!: string;
	public archivo!: string;
	public ordenDestacado!: number;
	public esDestacado!: boolean;

  constructor(){}
}