import {IEnvironment} from './IEnvironment';
import {KeycloakConfig} from 'keycloak-js';

const keycloakConfig: KeycloakConfig = {
  url: 'https://test.aleph.work/auth',
  realm: 'aleph',
  clientId: 'miawellness'
};

export const environment: IEnvironment = {
  production: true,
  store: 'tiendamiawellness',
  phoneNumber: '59899389189',
  idStore: 109,
  itemsPerPage: 10,
  title: 'mytreat',
  apiPortal: 'https://test.aleph.work/cms/api/public/',
  apiBase: 'https://test.aleph.work/api',
  // urlApp: 'http://localhost:4202/miawellness/' ,
  urlApp: 'https://mytreat.aleph.work/',
  homeCategory: 'HOME',
  siteName: 'mytreat',
  email: 'info@mytreat.health',
  googleTagManagerId: '',
  keycloakConfig,
  multisite: 'miawellness',
  idpHint: 'miawellness-oidc'
};
