<!--
{{element?.titulo}}
{{element?.linkValor}}
{{element?.tipoBanner}}
{{element?.imagen}}
{{element?.tipoVisualizacion}}
{{element?.ancho}}
{{element?.orden}}
{{element?.lenguaje}}
{{element?.fechaDesde | date:'shortDate'}}
{{element?.fechaFin | date:'shortDate'}}
-->

<!-- ANCHO TOTAL -->
<div *ngIf="element?.ancho === 0" class="full-width d-flex justify-content-center">
    <a *ngIf="element?.linkValor!=null && element?.linkValor!=''" href="{{element?.linkValor}}">
        <img class="img-responsive mma" itemprop="image" src="{{element?.imagen}}" alt="{{element?.titulo}}"
            [ngClass]="{'d-block d-sm-none': element?.tipoVisualizacion === 'MOVIL', 'd-none d-sm-block': element?.tipoVisualizacion === 'DESKTOP'}" />
    </a>
    <img  *ngIf="element?.linkValor==null || element?.linkValor==''" class="img-responsive mma" itemprop="image" src="{{element?.imagen}}" alt="{{element?.titulo}}"
            [ngClass]="{'d-block d-sm-none': element?.tipoVisualizacion === 'MOVIL', 'd-none d-sm-block': element?.tipoVisualizacion === 'DESKTOP'}" />
</div>

<!-- ANCHO POR COLUMNAS -->
<div *ngIf="element?.ancho !== 0" class="col-sm-{{element?.ancho}} p-2 d-flex justify-content-center" >
    <a *ngIf="element?.linkValor!=null && element?.linkValor!='' " href="{{element?.linkValor}}">
        <img class="img-responsive mma" itemprop="image" src="{{element?.imagen}}" alt="{{element?.titulo}}"
            [ngClass]="{'d-block d-sm-none': element?.tipoVisualizacion === 'MOVIL', 'd-none d-sm-block': element?.tipoVisualizacion === 'DESKTOP'}" />
    </a>
    <img  *ngIf="element?.linkValor==null || element?.linkValor==''" class="img-responsive mma" itemprop="image" src="{{element?.imagen}}" alt="{{element?.titulo}}"
    [ngClass]="{'d-block d-sm-none': element?.tipoVisualizacion === 'MOVIL', 'd-none d-sm-block': element?.tipoVisualizacion === 'DESKTOP'}" />
</div>
