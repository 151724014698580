import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Elemento } from '../models/elemento.model';
import { ElementoFiltro } from '../models/elementoFiltro.model';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class ElementService {
  private apiUrl = environment.apiPortal + environment.siteName + '/elementos';

  constructor(private http: HttpClient) {}

  currentDate = new Date();
  checkoutDate = new Date().setDate(this.currentDate.getDate() + 7);

  private buildQueryParams(params: { [key: string]: any }): HttpParams {
    let httpParams = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        httpParams = httpParams.append(key, params[key]);
      }
    }
    return httpParams;
  }

  public getAll(
    limite: number,
    desplazamiento: number,
    elementoFiltro: ElementoFiltro,
    order: string
  ): Observable<{ elementos: Elemento[]; total: number }> {
    const params: { [key: string]: any } = {};

    if (limite) params.limite = limite;
    if (desplazamiento) params.desplazamiento = desplazamiento;

    if (elementoFiltro) {
      if (elementoFiltro.id) params.id = elementoFiltro.id;
      if (elementoFiltro.categorias && elementoFiltro.categorias.length > 0)
        params.categoria = elementoFiltro.categorias.join();
      if (elementoFiltro.tipoModulo) params.tipoModulo = elementoFiltro.tipoModulo;
      if (elementoFiltro.nombre) params.nombre = elementoFiltro.nombre;
      if (elementoFiltro.copete) params.copete = elementoFiltro.copete;
      if (elementoFiltro.descripcion) params.descripcion = elementoFiltro.descripcion;
      if (elementoFiltro.descripcionHtml) params.descripcionHtml = elementoFiltro.descripcionHtml;
      if (elementoFiltro.fuente) params.fuente = elementoFiltro.fuente;
      if (elementoFiltro.estadoModulo) params.estadoModulo = elementoFiltro.estadoModulo;
      if (elementoFiltro.fechaInfoInicio) params.fechaInicioInfo = elementoFiltro.fechaInfoInicio.toISOString();
      if (elementoFiltro.fechaInicioInfoDesde) params.fechaInicioInfoDesde = elementoFiltro.fechaInicioInfoDesde.toISOString();
      if (elementoFiltro.fechaInicioInfoHasta) params.fechaInicioInfoHasta = elementoFiltro.fechaInicioInfoHasta.toISOString();
      if (elementoFiltro.fechaInfoFin) params.fechaFinInfo = elementoFiltro.fechaInfoFin.toISOString();
      if (elementoFiltro.fechaFinInfoDesde) params.fechaFinInfoDesde = elementoFiltro.fechaFinInfoDesde.toISOString();
      if (elementoFiltro.fechaFinInfoHasta) params.fechaFinInfoHasta = elementoFiltro.fechaFinInfoHasta.toISOString();
      if (elementoFiltro.institucion) params.institucion = elementoFiltro.institucion;
      if (elementoFiltro.pais) params.pais = elementoFiltro.pais;
      if (elementoFiltro.lenguaje) params.lenguaje = elementoFiltro.lenguaje;
      if (elementoFiltro.estado) params.estado = elementoFiltro.estado;
      if (elementoFiltro.fechaInicio) params.fechaInicio = elementoFiltro.fechaInicio.toISOString();
      if (elementoFiltro.fechaFin) params.fechaFin = elementoFiltro.fechaFin.toISOString();
      if (elementoFiltro.rolMinimo) params.rolMinimo = elementoFiltro.rolMinimo;
      if (elementoFiltro.modulo) params.modulo = elementoFiltro.modulo;
      if (elementoFiltro.orden) params.orden = elementoFiltro.orden;
      if (elementoFiltro.ordenDestacado) params.ordenDestacado = elementoFiltro.ordenDestacado;
      if (elementoFiltro.esDestacado) params.destacado = elementoFiltro.esDestacado;
    }
    if (order) params.order = order;

    const httpParams = this.buildQueryParams(params);

    return this.http.get<{ elementos: Elemento[]; total: number }>(this.apiUrl, { params: httpParams });
 
  }
  getElementById(id: number): Observable<Elemento> {
    return this.http.get<Elemento>(`${this.apiUrl}/${id}`);
  } 
}