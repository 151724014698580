<div class="container">
  <div class="row">

    <!-- <app-banner-aplicacion style="display: contents"></app-banner-aplicacion> -->

    <ng-container *ngFor="let element of datos">

      <app-banner-aplicacion *ngIf="element.tipoBanner== 'APLICACION'" [element]="element" style="display: contents">
      </app-banner-aplicacion>

      <app-banner-carrusel *ngIf="element.tipoBanner== 'CARRUSEL'" [element]="element" style="display: contents">
      </app-banner-carrusel>

      <app-banner-imagen *ngIf="element.tipoBanner== 'IMAGEN'" [element]="element" style="display: contents">
      </app-banner-imagen>

      <app-banner-personalizado *ngIf="element.tipoBanner== 'PERSONALIZADO'" [element]="element"
        style="display: contents">
      </app-banner-personalizado>

      <app-banner-titulo *ngIf="element.tipoBanner== 'TEXT'" [element]="element" style="display: contents">
      </app-banner-titulo>

      <app-banner-video *ngIf="element.tipoBanner== 'VIDEO'" [element]="element" style="display: contents">
      </app-banner-video>

    </ng-container>

  </div>
</div>