import { Component, OnInit } from "@angular/core";
import { AbstractPage } from "../abstract.page";
import { environment } from "src/environments/environment";
import { CategoryService } from "@services";
import { Category } from "@models";
import { SearchBoxService } from "../../services/search-box.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CategoriaService } from "src/app/services/categoria.service";
import { PlatformLocation } from "@angular/common";
import { ElementService } from "src/app/services/element.service";
import { Elemento } from "src/app/models/elemento.model";
import { ElementoFiltro } from "src/app/models/elementoFiltro.model";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { LangService } from "src/app/services/lang.service";

@Component({
  selector: "app-blog",
  templateUrl: "./blog.component.html",
  styleUrls: ["./blog.component.scss"],
})
export class BlogComponent extends AbstractPage implements OnInit {
  isEnglish = false;
  categorias$: any;
  category: Category;
  elementos$: Elemento[] = [];

  pageIndex = 0;
  desplazamiento = 0;
  limite = 3;
  totalItems = 0;

  constructor(
    private categoriaService: CategoriaService,
    private searchBoxService: SearchBoxService,
    private categoryService: CategoryService,
    private platformLocation: PlatformLocation,
    private elementoService: ElementService,
    private sanitizer: DomSanitizer,
    private langService: LangService,
    route: ActivatedRoute,
    public router: Router
  ) {
    super();
    this.searchBoxService.showSearchBox();
  }

  public pageTitle(): string {
    return environment.title;
  }

  ngOnInit(): void {
    this.langService.getLangV2().subscribe((lang) => {
      this.isEnglish = lang.toLowerCase().trim() === "en";
    });
    this.initializeData();
  }

  buildUrl(stringUrl: string) {
    const path = stringUrl.substring(0, stringUrl.lastIndexOf("?"));
    const queryParamsString = stringUrl.substring(
      stringUrl.lastIndexOf("?") + 1
    );
    const queryParamsArr = queryParamsString
      .split("&")
      .map((str) => str.split("="));
    const queryParamsObj = queryParamsArr.reduce((acc, curr) => {
      return { ...acc, [curr[0]]: curr[1] };
    }, null);

    return [path, queryParamsObj];
  }

  private initializeData() {
    this.categoriaService.getAll().subscribe((categoriaData) => {
      this.categorias$ = categoriaData.categorias;

      this.categoryService
        .getOneDeepSearch$(environment.homeCategory)
        .subscribe((categoryData) => {
          this.category = categoryData;

          const filtroEventos: ElementoFiltro = new ElementoFiltro();
          filtroEventos.estado = "activo";
          filtroEventos.modulo = "Eventos";
          filtroEventos.categorias = [];

          this.elementoService
            .getAll(
              this.limite,
              this.desplazamiento,
              filtroEventos,
              "ordenDestacado-asc"
            )
            .subscribe((data) => {
              if (data && data.elementos) {
                this.elementos$ = data.elementos.sort(
                  (a, b) => a.ordenDestacado - b.ordenDestacado
                );
              }
            });
        });
    });
  }

  public sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
