  <div class="breadcrumb">
    <div class="container">
      <a routerLink="'/'" i18n="Home">Home</a>
      <span> / </span> <span i18n="About us">About us</span>
    </div>
  </div>

  <div class="container">
    <h1 i18n="About us">About us</h1>
    <div class="text">
      <p class="about-us" i18n="About us p-1">
        At Mytreat, we believe that wellness, health, and quality of life are
        essential to living fully, especially during your travels. Our platform
        is designed to offer you a unique experience, connecting you with a wide
        variety of services dedicated to taking care of you and your loved ones,
        wherever you are.
      </p>
    </div>

    <h1 i18n="About us h-1">What is Mytreat?</h1>

    <div class="text">
      <p class="about-us" i18n="About us p-2">
        Mytreat is an innovative platform focused on tourists and travelers
        visiting Miami and other selected destinations around the world. We
        specialize in providing you access to the best wellness, health, and
        quality of life services, allowing you to make the most of your travels
        while taking care of yourself. Whether you're looking for a relaxing
        massage after a long flight, a yoga class by the sea, or a medical
        check-up with the latest preventive medicine technology, Mytreat has
        everything you need in one place. <br />
        <br />

        But at Mytreat, we don’t just focus on your well-being during travels;
        we are also committed to accompanying you in building valuable,
        enjoyable, and healthy longevity. We aim to be your ally on the journey
        to a longer and fuller life, helping you incorporate healthy habits that
        last beyond your vacations.
      </p>
    </div>

    <h1 i18n="About us h-2">Discover Mytreat</h1>

    <div class="text">
      <p class="about-us" i18n="About us p-3">
        Navigating Mytreat is simple and intuitive. From our homepage, choose
        the city where you'll enjoy the experience, then explore our service
        categories, ranging from health and fitness to emotional well-being and
        personal care, all specially designed to complement your travel
        experience. <br />
        <br />
      </p>
      <div class="list">
        <p i18n="About us p-4">
          <strong> Explore our services and events:</strong> Use our search tool
          or browse through our categories to discover the offering that best
          suits your needs during your stay. <br />
        </p>

        <p i18n="About us p-5">
          <strong> Meet our professionals:</strong> Each service comes with the
          option of having a specialized concierge who will accompany you during
          your experience to make everything easier, more accessible, and
          relaxed. You will also find testimonials that will help you learn more
          about the experience and the opinions of other users. <br />
        </p>

        <p i18n="About us p-6">
          <strong>Book and enjoy: </strong>Once you find the perfect service,
          booking is easy. You can choose the date and time that suits you best
          and complete your purchase securely through our platform, or you can
          connect with a concierge who will handle it for you. <br />
        </p>

        <p i18n="About us p-7">
          <strong>Personalized experiences:</strong> If you’re unsure which
          service is right for you, our team of concierges is available to
          advise and help you make the best decision. <br />
        </p>
      </div>
      <div class="text">
        <p class="about-us" i18n="About us p-8">
          At Mytreat, our goal is to provide you with a complete and
          personalized experience that allows you to take care of yourself and
          enjoy moments dedicated to your well-being, both during your travels
          and in your daily life. We want you to find in us the perfect ally for
          your healthy lifestyle and longevity.
        </p>
      </div>
    </div>
  </div>

  <!-- ESPAÑOL -->
  <!-- <div class="container" *ngIf="!isEnglish">
    <h1>Sobre Nosotros</h1>
    <div class="text">
      <p class="about-us">
        En Mytreat, creemos que el bienestar, la salud y la calidad de vida son
        esenciales para vivir plenamente, especialmente durante tus viajes.
        Nuestra plataforma está diseñada para ofrecerte una experiencia única,
        conectándote con una amplia variedad de servicios dedicados a cuidar de
        ti y de tus seres queridos, dondequiera que estés.
      </p>
    </div>

    <h1>¿Qué es Mytreat?</h1>

    <div class="text">
      <p class="about-us">
        Mytreat es una plataforma innovadora enfocada en turistas y viajeros que
        visitan Miami y otros destinos seleccionados alrededor del mundo. Nos
        especializamos en ofrecerte acceso a los mejores servicios de bienestar,
        salud y calidad de vida, permitiéndote disfrutar al máximo de tus viajes
        mientras cuidas de ti. Ya sea que estés buscando un masaje relajante
        después de un largo vuelo, una clase de yoga frente al mar, o un estudio
        médico con la mejor tecnología en medicina preventiva, Mytreat tiene
        todo lo que necesitas en un solo lugar. <br />
        <br />
        Pero en Mytreat no solo nos enfocamos en tu bienestar durante los
        viajes; también estamos comprometidos en acompañarte en la construcción
        de una longevidad valiosa, disfrutable y en salud. Queremos ser tu
        aliado en el camino hacia una vida más larga y plena, ayudándote a
        incorporar hábitos saludables que perduren más allá de tus vacaciones.
      </p>
    </div>

    <h1>Descubre Mytreat</h1>

    <div class="text">
      <p class="about-us">
        Navegar en Mytreat es simple e intuitivo. Desde nuestra página
        principal, elije la ciudad donde disfrutarás la experiencia, ahí puedes
        explorar nuestras categorías de servicios, desde salud y fitness hasta
        bienestar emocional y cuidado personal, especialmente diseñados para
        complementar tu experiencia de viaje. <br />
        <br />
      </p>
      <div class="list">
        <p>
          <strong> Explora nuestros servicios y eventos: </strong> Utiliza
          nuestro buscador o navega por nuestras categorías para descubrir la
          propuesta que más se ajuste a lo que necesitas durante tu estadía.
          <br />
        </p>

        <p>
          <strong>Conoce a nuestros profesionales:</strong> Cada servicio viene
          acompañado de la posibilidad de contar con un concierge especializado
          que te acompañará durante tu experiencia para hacer todo más fácil,
          accesible y relajado. Encontrarás también testimonios que te ayudarán
          a conocer más sobre la experiencia y las opiniones de otros usuarios.
          <br />
        </p>

        <p>
          <strong>Reserva y disfruta: </strong>Una vez que encuentres el
          servicio perfecto, reservar es muy fácil. Puedes elegir la fecha y
          hora que mejor te convenga, y completar tu compra de manera segura a
          través de nuestra plataforma o puedes conectar con un concierge, que
          lo hará por ti.
          <br />
        </p>

        <p>
          <strong>Experiencias personalizadas:</strong> Si no estás seguro de
          qué servicio es el adecuado para ti, nuestro equipo de concierges está
          disponible para asesorarte y ayudarte a tomar la mejor decisión.
          <br />
        </p>
      </div>
      <div class="text">
        <p class="about-us">
          En Mytreat, nuestro objetivo es brindarte una experiencia completa y
          personalizada que te permita cuidar de ti y disfrutar de momentos
          dedicados a tu bienestar, tanto en tus viajes como en tu vida diaria.
          Queremos que encuentres en nosotros el aliado perfecto para tu estilo
          de vida saludable y tu longevidad.
        </p>
      </div>
    </div>
  </div> -->

  <div class="container video">
    <iframe
      height="630"
      src="https://www.youtube.com/embed/KDRmd3OfWoc?si=Pe7waiObVuANtK_6"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    ></iframe>
  </div>
