import { Component, OnInit } from '@angular/core';
import { AbstractPage } from 'src/app/pages/abstract.page';
import { environment } from 'src/environments/environment';
import { SearchBoxService } from 'src/app/services/search-box.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PlatformLocation } from "@angular/common";
import { ElementService } from 'src/app/services/element.service';
import { Elemento } from 'src/app/models/elemento.model';
import { ElementoFiltro } from 'src/app/models/elementoFiltro.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LangService } from "src/app/services/lang.service";

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent extends AbstractPage implements OnInit {

  // IsEnglish = false;
  isEnglish = false;
  elemento: Elemento;
  idElemento: number;

  constructor(
    private searchBoxService: SearchBoxService,
    private platformLocation: PlatformLocation,
    private sanitizer: DomSanitizer,
    private elementoService: ElementService,
    private route: ActivatedRoute,
    private langService: LangService,
    private router: Router
  ) {
    super();
    this.searchBoxService.showSearchBox();
  }

  public pageTitle(): string {
    return environment.title;
  }

  ngOnInit(): void {


    this.langService.getLangV2().subscribe((lang) => {
      this.isEnglish = lang.toLowerCase().trim() === "en";
    });
    // console.log(this.isEnglish)

    this.route.queryParams.subscribe((params) => {
      this.idElemento = +this.router.url.substring(
        this.router.url.lastIndexOf('/') + 1,
        this.router.url.length
      );
      // console.log(this.idElemento);

      this.loadEvent(this.idElemento);
    });
  }

  private loadEvent(id: number) {
    var filtroDetalle: ElementoFiltro = new ElementoFiltro();
    filtroDetalle.id = id;
    this.elementoService.getAll(0, 0, filtroDetalle, '').subscribe(
      (data) => {
        this.elemento = (data as any)['elementos'][0];
        if (this.elemento && this.elemento.fechaInfoInicio != null) {
          this.elemento.fechaInfoInicio = new Date(this.elemento.fechaInfoInicio);
        }
      },
      // (error) => {
      //   console.error('Error al obtener el evento:', error);
      // }
    );
  }

  public sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
