import {Component} from '@angular/core';
import {CategoryService} from '@services';
import {AbstractPage} from '../../abstract.page';
import {Category} from '@models';
import {SearchBoxService} from '../../../services/search-box.service';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-disclaimer-page',
  templateUrl: './disclaimer-page.html',
  styleUrls: []
})
export class DisclaimerPage extends AbstractPage {

  public category: Category;

  constructor(
    private searchBoxService: SearchBoxService,
    private categoryService: CategoryService,
    route: ActivatedRoute) {
    super();
    this.searchBoxService.showSearchBox();

  }

  pageTitle(): string {
    return environment.title;
  }

  ngOnInit() {
    this.categoryService.getOneDeepSearch$(environment.homeCategory).subscribe(data => {
      //console.log('esteeeeeeee', environment.homeCategory, data);
      this.category = data;
    });
  }

}