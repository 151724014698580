<div class="appDetailsContent">
  <div class="breadcrumb"><!-- appCats -->
    <div class="container">
      <a i18n="Home" [routerLink]="'/'">Home</a>
      <ng-container *ngFor="let category$ of this.breadscrum">
        <ng-container *ngIf="category$ | async as category">
          <span> / </span>
          <a [routerLink]="'/'+category.fullIdExterno">{{category.nombre}}</a>
        </ng-container>
      </ng-container>
      <span> / </span>
      <a *ngIf="product">{{product.nombre}}</a>
    </div>
  </div>
</div>
<div class="headerAppDetails">
  <div class="hero">
    <div class="bgImageApp hero__image" style="background-image: url({{product?.imgBannerUrl}})"></div>
  </div>

  <ng-container *ngIf="product">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="header-overlaped"><!-- headerSingleAppDetails -->
            <mat-card class="product-card mat-elevation-z0">
              <img mat-card-image src="{{product.imgUrl}}" alt="{{product.nombre}}">
              <mat-card-content>
                <div class="row">
                  <div class="col-12 col-md-10">
                    <div class="mat-card-category"></div> <!-- <div class="subtitle text-uppercase"></div> -->
                    <h1 class="mat-card-title mat-typography__underscore">{{product.nombre}}</h1>
                    <div class="mat-card-description" [innerHTML]="product.descripcion | safeHtml:'html'">
                    </div>
                    <div class="tags">
                      <a [routerLink]="'/'+category.fullIdExterno" *ngFor="let category of product.categorias" mat-button>
                        <i class="material-icons">arrow_right</i>
                        {{category.nombre}}
                      </a>
                    </div>
                  </div>
                  <div *ngIf="product.permiteInteresado && !!user" style="text-align: center" class="col-12 col-md-4">
                    <div>
                      <p i18n="TextInterested">I'm interested</p>
                    </div>
                    <div>
                      <button [disabled]="disableInterest" (click)='doInterested()' mat-flat-button color="primary">
                        <span *ngIf="!disableInterest" i18n="InterestdButtonText1">Contact me</span>
                        <span *ngIf="disableInterest" i18n="InterestdButtonText2">We will contact you soon</span>
                      </button>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <mat-tab-group [selectedIndex]="selectedTab" class="mat-tab-group--details">
            <mat-tab>
              <!-- Tab Header -->
              <ng-template mat-tab-label>
                <span i18n="GeneralInfoTab | Tab de informacion general en la vista del producto">General Info</span>
              </ng-template>
              <div class="row">
                <div [ngClass]="product.permiteComprar?'col-md-9':'col-md-12'">
                  <div class="html-content" [innerHTML]="product.infoGeneralHtmtl | safeHtml:'html'"></div>
                </div>
                <div *ngIf="product.permiteComprar" class="col-md-3">
                  <div class="sidebarAppDetails">
                    <mat-expansion-panel expanded="true" class="plans-expansion mat-elevation-z0">
                      <mat-expansion-panel-header [expandedHeight]="'47px'">
                        <mat-panel-title>
                          <span i18n="PlansTitlePanel | Lista de planes en pagina de producto">Plans</span>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <mat-list>
                        <ng-container *ngFor="let plan of product.planes">
                          <mat-list-item>
                            <div class="pricePlan">
                              <p>{{plan.nombre}} <span [style.visibility]="plan?.mesesContratacion > 1?'visible':'hidden'" i18n="PlanTerm | label with the duration of the plan">({{plan.mesesContratacion}} Month plan)</span></p>
                              <ng-container *ngFor="let precio of plan.precios">
                                <p *ngIf="precio.tipo == 'MENSUAL'">
                                  <ng-container *ngIf="precio.precio > 0">
                                    <span>{{precio?.moneda.simbolo}}</span>{{precio.precio | number:'0.2-2'}}
                                  </ng-container>
                                </p>
                              </ng-container>
                            </div>
                          </mat-list-item>
                          <mat-divider *ngIf="product.planes[product.planes.length-1].idExterno !== plan.idExterno" class="bt_accent"></mat-divider>
                        </ng-container>
                      </mat-list>
                      <button *ngIf="sumAllProductsPrice >0" (click)='moveToSelectedTab("PlansAndPrices")' mat-flat-button color="primary" class="mat-button--block" i18n="ButtonBuyNow | Boton comprar ahora de planes y precios">BUY NOW</button>
                      <button *ngIf="sumAllProductsPrice ===0" (click)='moveToSelectedTab("PlansAndPrices")' mat-flat-button color="primary" class="mat-button--block" i18n="ButtonImInterested | Boton estoy interesados de planes y precios">I'm interested</button>
                    </mat-expansion-panel>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab *ngIf="product.infoSpectHtml !== undefined && product.infoSpectHtml !== null && product.infoSpectHtml !== ''">
              <!-- Tab Header -->
              <ng-template mat-tab-label>
                <span i18n="Functionality | Tab de informacion de funcionalidad">Functionality</span>
              </ng-template>

              <!-- Tab Content -->
              <div class="row">
                <div class="col-md-9">
                  <div class="html-content" [innerHTML]="product.infoSpectHtml | safeHtml:'html'"></div>
                </div>
              </div>
            </mat-tab>
            <mat-tab *ngIf="product.infoFaqsHtml !== undefined && product.infoFaqsHtml !== null && product.infoFaqsHtml !== ''" label="FAQ’s">
              <div class="row">
                <div class="col-md-9">
                  <div class="html-content" [innerHTML]="product.infoFaqsHtml | safeHtml:'html'"></div>
                </div>
              </div>
            </mat-tab>
            <mat-tab *ngIf="product.infoTermsHtml !== undefined && product.infoTermsHtml !== null && product.infoTermsHtml !== ''">
              <!-- Tab Header -->
              <ng-template mat-tab-label>
                <span i18n="TermsAndConditions | Tab de informacion planes y precios">Terms & Conditions</span>
              </ng-template>

              <!-- Tab Content -->
              <div class="row">
                <div class="col-md-9">
                  <div class="html-content" [innerHTML]="product.infoTermsHtml | safeHtml:'html'"></div>
                </div>
              </div>
            </mat-tab>
            <mat-tab *ngIf="product.permiteComprar">
              <!-- Tab Header -->
              <ng-template mat-tab-label>
                <span class="navegable" id="PlansAndPrices" i18n="PlansAndPrices | Tab de informacion planes y precios">Plans & Prices</span>
              </ng-template>

              <!-- Tab Content -->
              <div class="grid grid--plans">
                <mat-card class="plan-card mat-elevation-z0" *ngFor="let plan of  getPlanesOrdenados()">
                  <mat-card-title>{{plan.nombre}}</mat-card-title>
                  <mat-card-content>
                    <div class="values">
                      <ng-container *ngFor="let precio of plan.precios">
                        <div class="values__plan" *ngIf="precio?.tipo == 'MENSUAL'">
                          <ng-container *ngIf="precio.precio >0">
                            <span>{{precio?.moneda?.simbolo}}</span>{{precio.precio | number:'0.2-2'}}
                          </ng-container>
                        </div>
                        <div class="values__installation" *ngIf="precio?.tipo == 'INSTALACION'">
                          <span i18n="Installation cost | Costo de instalacion en vista de plan">Installation cost: <span>{{precio?.moneda?.simbolo}} </span>{{precio.precio | number:'0.2-2'}}</span>
                        </div>
                      </ng-container>
                    </div>

                    <div class="text-wrap">
                      <div *ngIf="plan.infoGeneralHtml">
                        <span [innerHTML]="plan.infoGeneralHtml | safeHtml:'html'"></span>
                      </div>
                      <p>{{plan.descripcion}}</p>
                    </div>
                  </mat-card-content>
                  <mat-card-actions>
                    <ng-container *ngIf="!!user">
                      <a *ngIf="!plan.hideBuy" mat-flat-button color="primary" class="mat-button--block" [routerLink]="'/product/'+product.idExterno+'/purchase/'+plan.idExterno" i18n="ButtonBuyNowEnPlan | Boton comprar ahora en el plan en si">
                        BUY NOW
                      </a>
                      <a *ngIf="plan.hideBuy" mat-flat-button color="primary" class="mat-button--block" [routerLink]="'/product/'+product.idExterno+'/purchase/'+plan.idExterno" i18n="ButtonImInterestedEnPlan | Boton estoy interesado ahora en el plan en si">
                        I'm interested
                      </a>
                    </ng-container>
                    <ng-container *ngIf="!user">
                      <span i18n="LoginToContinueText">To buy this content please login</span>
                      <a *ngIf="!plan.hideBuy" mat-flat-button color="primary" class="mat-button--block mt-2" [routerLink]="'/product/'+product.idExterno+'/purchase/'+plan.idExterno" i18n="LoginButtonText">
                        Login
                      </a>
                    </ng-container>
                    <span [style.visibility]="plan?.mesesContratacion > 1?'visible':'hidden'" class="period" i18n="SubscriptionPeriod | Periodo de suscripcion en la card de plan">
                      Subscription period of <strong>{{plan?.mesesContratacion}} Month/s</strong>
                    </span>
                  </mat-card-actions>
                </mat-card>
              </div>
            </mat-tab>
            <mat-tab *ngIf="(product.mostrarInfoContacto !=='NO') && ((product.mostrarInfoContacto === 'SIEMPRE' && product.soporteHtml !== undefined)|| (product.mostrarInfoContacto=== 'LOGUEADO'))">
              <!-- Tab Header -->
              <ng-template mat-tab-label>
                <span i18n="Contact | Tab de contacto">Contact Info</span>
              </ng-template>

              <!-- Tab Content -->
              <div class="row">
                <div class="col-md-12">
                  <div class="iniciarSesionTab" *ngIf="!user && product.mostrarInfoContacto=== 'LOGUEADO'">
                    <div>
                      <p i18n="TextForLogin | Boton para loguear">To access this content please login</p>
                    </div>
                    <div>
                      <button (click)='doLogin()' mat-flat-button color="primary" i18n="DoLogin | Boton para loguear">Login</button>
                    </div>
                  </div>
                  <div *ngIf="!user && product.mostrarInfoContacto=== 'LOGUEADO'" class="html-content" [innerHTML]="product.soporteHtml | safeHtml:'html'"> </div>
                  <div *ngIf="product.mostrarInfoContacto=== 'SIEMPRE'" class="html-content" [innerHTML]="product.soporteHtml | safeHtml:'html'"></div>

                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </ng-container>
</div>
